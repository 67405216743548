/* src/components/schedule3/MatchSchedule.css */

/* Reset and Root Styles */
.match-schedule-root html, 
.match-schedule-root body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
}

.match-schedule-root {
  display: flex;
  height:calc(100% + 16px);
  width: calc(100% + 32px);
  margin-left:-16px;
  margin-bottom:-16px;
  overflow: hidden;
  flex-direction:column;
}

/* Crew Column Styles */
/* .match-schedule-column.crew {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 15px;
  width: 24%; 
  box-sizing: border-box;
  overflow-y: auto; 
  height: 100vh; 
  
} */

/* Schedule Columns Container */
/* .match-schedule-columns {
  display: flex;
  flex: 1;
  padding: 15px;
  box-sizing: border-box;
  overflow-x: auto;
  height: 100vh;
  
 
  gap: 10px;
  margin:0;
} */
.match-schedule-column.schedule.collapsed {
    flex: 0 0 30px !important;       /* enough width to see the icon */
    width: 30px !important;
    min-width: 30px !important;
    min-height:300px !important;
  padding: 0 !important;
  margin-right: 0 !important;
  overflow: hidden;
}

/* Individual Schedule Column Styles */
.match-schedule-column.schedule {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: all 0.2s ease;
  background-color:#B3CDE6;
  /* Remove the fixed width and padding from here:
  flex: 0 0 300px;
  padding: 15px; 
  */

  /* Use flex: 1 to allow columns to share the space equally. */
  flex: 1; 

  /* (Optional) If you want a minimum width so they don't get too skinny:
     flex: 1 1 200px; 
     or 
     min-width: 200px;
  */

  /* keep if you still want spacing between columns */
  box-sizing: border-box;
  overflow-y: auto;
  /* height: calc(100vh - 120px); */
  border-radius: 5px;
  padding:15px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}


/* Title Styles */
.title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px; /* Spacing below title */
}

/* Draggable Crew List Styles */
/* .draggable-crew-list {
  flex: 1 1 auto;
  min-height: 60px; 
  box-sizing: border-box;
  overflow: hidden; 
  padding: 10px;
  border-radius: 5px;
  cursor: grab;
  transition: background-color 0.2s ease;
} */

.draggable-crew-list:hover {
  background-color: #f0f0f0; /* Highlight on hover */
}

/* Droppable Job Styles */
.droppable-job {
  /* Styles are handled inline in the component */
  /* Optionally, add transition for smoother visual feedback */
  transition: background-color 0.2s ease, opacity 0.2s ease;
}

/* Scrollbar Styling (Optional) */
.match-schedule-column.crew::-webkit-scrollbar,
.match-schedule-columns::-webkit-scrollbar,
.match-schedule-column.schedule::-webkit-scrollbar {
  width: 8px;
}

.match-schedule-column.crew::-webkit-scrollbar-thumb,
.match-schedule-columns::-webkit-scrollbar-thumb,
.match-schedule-column.schedule::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.match-schedule-column.crew::-webkit-scrollbar-track,
.match-schedule-columns::-webkit-scrollbar-track,
.match-schedule-column.schedule::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}



/* Adjust the Crew and Filters Section */
.match-schedule-column.crew {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 15px;
  width: 100%; /* Use full width */
  box-sizing: border-box;
  overflow: hidden;
  height: auto; /* Adjust height to fit content */
}

/* Title Row: Filters on the left, Crew on the right */
.match-schedule-header {
  display: flex;
  flex-direction: row; /* Ensure Crew & Filters are side by side */
  justify-content: space-between; /* Keep Crew List on the left, Filters on the right */
  align-items: flex-end; /* Aligns both at the bottom of their container */
  width: 100%;
  padding: 10px;
}

/* Filters Section */
.filters-section {
  display: flex;
  flex-direction: column;
  flex: 1;
}

/* Crew Section */
.crew-section {
  display: flex;
  flex-direction: column;
  min-width:250px;
  margin-right:8px;
  /* Make it take up all available height */
  flex-grow: 1;
  
  /* Ensure it stays within its container */
  max-height: 200; /* Important for flex containers to allow scrolling */
  
  /* Enable scrolling within the section */
  overflow-y: auto;
}


/* Crew Cards Row: Horizontal Scroll */
.crew-card-container {
  display: flex;
  flex-direction: row; /* Arrange crew cards horizontally */
  gap: 10px;
  overflow-x: auto; /* Enable horizontal scrolling */
  padding: 10px 0;
  white-space: nowrap; /* Prevent cards from wrapping */
}

/* Individual Crew Cards */
.draggable-crew-list {
  min-width: 50px;
  min-height: 80px;
  box-sizing: border-box;
  overflow: hidden;
  padding: 5px;
  border-radius: 5px;
  cursor: grab;
  transition: background-color 0.2s ease;
  background-color: #f0f0f0;
  text-align: center;
}

/* Divider Line */
.match-schedule-divider {
  width: 100%;
  height: 2px;
  background-color: #ccc;
  margin: 15px 0;
}

/* Match Schedule Columns */
.match-schedule-columns {
  display: flex;
  flex: 1;
  padding: 0px;
  box-sizing: border-box;
  overflow-x: auto;
  height: calc(90vh - 100px);
  gap: 10px;
  margin: 0;
}
.crew-list-column {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0px;

  /* Set a max height so it never exceeds the screen */
  height: calc(90vh - 100px);
  
  /* Make it scroll independently if content overflows */
  overflow-y: auto;
}
.crew-list-column::-webkit-scrollbar {
  width: 8px;
}
.crew-list-column::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.crew-list-column::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.05);
}
.crew-chip {
  width: 40px;
  text-align: center;
  font-size: 14px;
}
.filters-container {
  display: flex;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start; /* Align items from the left */
  width: 100%;
  margin-top: auto; /* Pushes to bottom of parent div */
}

/* Filter Group */
.filter-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  background: #f8f9fa;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);

  /* Make each filter group take equal space */
  flex: 1; 
  min-width: 150px; /* Prevents it from becoming too small */
}

/* Filter Titles */
.filter-title {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 4px;
}

.autocomplete-chip-container {
  display: flex;
  flex-wrap: nowrap; /* Prevents wrapping to a new line */
  overflow-x: auto; /* Enables horizontal scrolling */
  gap: 4px; /* Adds spacing between chips */
  padding: 4px 0; /* Keeps padding minimal */
  max-width: 100%; /* Prevents overflow */
  white-space: nowrap; /* Ensures items stay in a single line */
}

.autocomplete-chip-container::-webkit-scrollbar {
  height: 6px; /* Thin scrollbar */
}

.autocomplete-chip-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.crew-list-wrapper {
  display: flex;
  flex-direction: column;
  width:225px;
  min-height:75px;
}

/* Sticky Icon Header (Doesn't scroll) */
.icon-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
}

/* Scrollable Crew List */
.crew-list-column {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0px;
  height: calc(90vh - 120px); /* Adjusted to prevent overlap */
  overflow-y: auto;
}